import { Link } from "react-router-dom";

export default function FeatureEmbeddingMachine() {
  return (
    <>
      <div className="inner-heading">
        <div className="container sml-container">
          <h2 className="medium-heading text-black"> Feature-augmented Embedding Machine </h2>
        </div>
      </div>

      <section className="tools-section tools-spacing">
        <div className="tools-tabs position-relative">
          <div className="container sml-container">
            <div className="tab-content" id="pills-tabContent">
              <div className="tab-pane fade show active" id="pills-genomaps" role="tabpanel" aria-labelledby="pills-genomaps-tab" tabIndex={0}>
                <div className="d-flex flex-column flex-md-row align-items-center justify-content-between">
                  <div className="left">
                    <h3 className="fs-32 text-black fw-500 mb-24px"> Tebular Data </h3>
                    <p className="text-gray">
                      Tebular Data is an entropy-based cartography strategy to
                      contrive the high dimensional gene expression data into a
                      configured image format, referred to as genomap, with
                      explicit integration of the genomic interactions. This
                      unique cartography casts the gene-gene interactions into
                      the spatial configuration of genomaps and enables us to
                      extract the deep genomic interaction features and discover
                      underlying discriminative patterns of the data.Reference:
                      Islam, M.T., and Xing, L., 2023. Cartography of Genomic
                      Interactions Enables Deep Analysis of Single-Cell
                      Expression Data, in press, Nature Communications.
                    </p>
                    <Link to="/geno-map" className="button button__primary mt-4"> See example </Link>
                  </div>
                  <div className="right">
                    <img src="assets/img/how-work2.png" alt="toool image" className="img-fluid" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
