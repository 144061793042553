import { Link } from 'react-router-dom';

export default function Pricing() {
  return (

   <section className="pricing pricing-spacing">
        <div className="inner-heading">
            <div className="container sml-container">
                <h2 className="medium-heading text-black">Pricing</h2>
            </div>
        </div>
 
        <div className="pricing__body">
            <div className="container sml-container">
                <div className="pricing__body__heading">
                    <h2 className="medium-heading text-black mb-24px">We’ve got a plan that’s perfect for you</h2>
                    <p className="font-dm mb-5 text-gray ">Find, contact, collaborate, negotiate, track posts &amp; manage payments. All in one influencer marketing suite.</p>
                </div>
            
                <div className="package-button">
                    <div className="package-button__item">
                        <input type="radio" name="package" defaultChecked />
                        <span>Monthly billing</span>
                    </div>
                    <div className="package-button__item">
                        <input type="radio" name="package" />
                        <span>Yearly billing</span>
                    </div>
                </div>
            
                <div className="pricing__box">
                    <div className="pricing-item position-relative">
                        <h3 className="fs-24 fw-500 pricing-name">Basic plan</h3>
                        <div className="pricing-price">
                            <h2>$20 <span className="per-use">Per user</span></h2>
                            <p className="para">Basic features up to 5 users</p>
                        </div>
                        <a href="/" className="button button__primary w-100 text-center">Get the plan</a>
                        <div className="pricing-item__list">
                            <h4 className="fs-20 fw-500">Features</h4>
                            <ul className="offer-list">
                                <li>Influencer message limit</li>
                                <li>Number of hyp.link</li>
                                <li>Number of general Campaigns</li>
                                <li>Influencer invitation limit</li>
                                <li>Daily use of advance filter &amp; search</li>
                                <li>Dedicated account manager support</li>
                            </ul>
                        </div>
                    </div>
                    <div className="pricing-item position-relative">
                        <span className="tag">Premium</span>
                        <h3 className="fs-24 fw-500 pricing-name">Business Plan</h3>
                        <div className="pricing-price">
                            <h2>$20 <span className="per-use">Per user</span></h2>
                            <p className="para">Basic features up to 5 users</p>
                        </div>
                        <a href="/" className="button button__primary w-100 text-center">Get the plan</a>
                        <div className="pricing-item__list">
                            <h4 className="fs-20 fw-500">Features</h4>
                            <ul className="offer-list">
                                <li>Campaign Management</li>
                                <li>Customer Support</li>
                                <li>Influencer message limit</li>
                                <li>Influencer invitation limit</li>
                                <li>Daily use of advance filter &amp; search</li>
                                <li>Dedicated account manager support</li>
                            </ul>
                        </div>
                    </div>
                    <div className="pricing-item position-relative">
                        <h3 className="fs-24 fw-500 pricing-name">Enterprise plan</h3>
                        <div className="pricing-price">
                            <h2>$20 <span className="per-use">Per user</span></h2>
                            <p className="para">Basic features up to 5 users</p>
                        </div>
                        <a href="/" className="button button__primary w-100 text-center">Get the plan</a>
                        <div className="pricing-item__list">
                            <h4 className="fs-20 fw-500">Features</h4>
                            <ul className="offer-list">
                                <li>Influencer message limit</li>
                                <li>Number of hyp.link</li>
                                <li>Number of general Campaigns</li>
                                <li>Influencer invitation limit</li>
                                <li>Daily use of advance filter &amp; search</li>
                                <li>Dedicated account manager support</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  );
}
