import { Link } from 'react-router-dom';
import { tutorialLink } from '../../config';

export default function Tutorial() {
  return (
    <>
        <div className=" inner-heading">
            <div className="container sml-container">
            <h2 className="medium-heading text-black">Tutorial</h2>
            </div>
        </div>

        <div className="tutorial-section py-96px">
            <div className="container sml-container">
                <div className="row items mb-4">
                    <div className="col-md-6 items-left mb-3">
                        <h3 className="text-black fs-40 fw-500 mb-24px">Upload File</h3>
                        <ul className="list">
                            <li className="list-item fs-24 text-gray mb-3">File must be in .csv format to start</li>
                            <li className="list-item fs-24 text-gray mb-3">Input your previous session ID</li>
                        </ul>
                        <Link to="/" className="button button__primary mt-4">Show Details</Link>
                    </div>
                    <div className="col-md-6 items-right mb-3">
                        <iframe className="w-100 video" height={400} src={tutorialLink} title="Oporadhi | Ankur Mahamud Feat Arman Alif | Bangla Song 2018 | Official Video" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen />
                    </div>
                </div>
                <div className="row items mb-4">
                    <div className="col-md-6 items-left mb-3">
                        <h3 className="text-black fs-40 fw-500 mb-24px">Select Visualization Methods</h3>
                        <ul className="list">
                            <li className="list-item fs-24 text-gray mb-3">Deep learning embedding of Genomaps</li>
                            <li className="list-item fs-24 text-gray mb-3">t-SNE of input data</li>
                            <li className="list-item fs-24 text-gray mb-3">t-SNE of input data</li>
                            <li className="list-item fs-24 text-gray mb-3">t-SNE of input data</li>
                        </ul>
                        <Link to="/" className="button button__primary mt-4">Show Details</Link>
                    </div>
                    <div className="col-md-6 items-right mb-3">
                        <iframe className="w-100 video" height={400} src={tutorialLink} title="Oporadhi | Ankur Mahamud Feat Arman Alif | Bangla Song 2018 | Official Video" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen />
                    </div>
                </div>
                <div className="row items mb-4">
                    <div className="col-md-6 items-left mb-3">
                        <h3 className="text-black fs-40 fw-500 mb-24px">Upload File</h3>
                        <ul className="list">
                            <li className="list-item fs-24 text-gray mb-3">File must be in .csv format to start</li>
                            <li className="list-item fs-24 text-gray mb-3">Input your previous session ID</li>
                        </ul>
                        <Link to="/" className="button button__primary mt-4">Show Details</Link>
                    </div>
                    <div className="col-md-6 items-right mb-3">
                        <iframe className="w-100 video" height={400} src={tutorialLink} title="Oporadhi | Ankur Mahamud Feat Arman Alif | Bangla Song 2018 | Official Video" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen />
                    </div>
                </div>
            </div>
        </div>
    </>
  );
}
