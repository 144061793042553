import { Link } from "react-router-dom";

export default function Publication() {
  return (
   <>
        <div className=" inner-heading">
            <div className="container sml-container">
            <h2 className="medium-heading text-black">Publications</h2>
            </div>
        </div>
       
        <div className="publication-body">
            <div className="container mb-4">
                <div className="d-flex justify-content-center">
                    <ul className="bullet-list">
                    <li>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.</li>
                    <li>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.</li>
                    <li>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.</li>
                    <li>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.</li>
                    <li>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.</li>
                    <li>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.</li>
                    <li>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.</li>
                    <li>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.</li>
                    <li>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.</li>
                    </ul>
                </div>
            </div>
            
            <div className="pagination">
                <Link to="#" className="page-link">
                    <svg width={7} height={12} viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.1578 11.6014C6.23591 11.524 6.2979 11.4318 6.34021 11.3303C6.38252 11.2287 6.4043 11.1198 6.4043 11.0098C6.4043 10.8998 6.38252 10.7908 6.34021 10.6893C6.2979 10.5877 6.23591 10.4956 6.1578 10.4181L2.34113 6.60144C2.26303 6.52397 2.20103 6.4318 2.15872 6.33026C2.11642 6.22871 2.09463 6.11978 2.09463 6.00977C2.09463 5.89977 2.11642 5.79084 2.15872 5.68929C2.20103 5.58774 2.26303 5.49558 2.34113 5.41811L6.1578 1.60144C6.23591 1.52397 6.2979 1.43181 6.34021 1.33026C6.38252 1.22871 6.4043 1.11978 6.4043 1.00977C6.4043 0.899765 6.38252 0.790844 6.34021 0.689294C6.2979 0.587745 6.23591 0.495577 6.1578 0.418108C6.00166 0.262899 5.79045 0.175781 5.5703 0.175781C5.35014 0.175781 5.13893 0.262899 4.9828 0.418108L1.1578 4.24311C0.689631 4.71186 0.426665 5.34727 0.426665 6.00977C0.426665 6.67228 0.689631 7.30769 1.1578 7.77644L4.9828 11.6014C5.13893 11.7566 5.35014 11.8438 5.5703 11.8438C5.79045 11.8438 6.00166 11.7566 6.1578 11.6014Z" fill="#595959" />
                    </svg>
                </Link>
                <Link to="#" className="page-link active">1</Link>
                <Link to="#" className="page-link">2</Link>
                <Link to="#" className="page-link">3</Link>
                <Link to="#" className="page-link">
                    <svg width={7} height={12} viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.842201 0.39856C0.764094 0.476028 0.702099 0.568195 0.659792 0.669744C0.617484 0.771294 0.595703 0.880216 0.595703 0.990225C0.595703 1.10023 0.617484 1.20916 0.659792 1.31071C0.702099 1.41226 0.764094 1.50442 0.842201 1.58189L4.65887 5.39856C4.73697 5.47603 4.79897 5.5682 4.84128 5.66974C4.88358 5.77129 4.90537 5.88021 4.90537 5.99023C4.90537 6.10023 4.88358 6.20916 4.84128 6.31071C4.79897 6.41226 4.73697 6.50442 4.65887 6.58189L0.842202 10.3986C0.764095 10.476 0.702099 10.5682 0.659792 10.6697C0.617485 10.7713 0.595703 10.8802 0.595703 10.9902C0.595703 11.1002 0.617485 11.2092 0.659792 11.3107C0.702099 11.4123 0.764095 11.5044 0.842202 11.5819C0.998337 11.7371 1.20955 11.8242 1.4297 11.8242C1.64986 11.8242 1.86107 11.7371 2.0172 11.5819L5.8422 7.75689C6.31037 7.28814 6.57333 6.65273 6.57333 5.99023C6.57333 5.32772 6.31037 4.69231 5.8422 4.22356L2.0172 0.39856C1.86107 0.24335 1.64986 0.156231 1.4297 0.156231C1.20955 0.156231 0.998337 0.24335 0.842201 0.39856Z" fill="#595959" />
                    </svg>
                </Link>
            </div>
        </div>
   </>
  );
}
