import Joi from "joi-browser";
import React, { useState } from "react";
import { Link } from 'react-router-dom';
import Input from "../../component/common/Input";
import { adminPanel } from "../../config";
import { handleOnChangeData } from "../../utils/form";

export default function SignUp() {

    const [formData, setFormData] = useState({ name: '', email: '', password: '', isAccepted: false });
    const [formErrors, setFormErrors] = useState({});

    const dataSchema = {
        name: Joi.string().trim().min(3).max(255).required().label("Name"),
        email: Joi.string().trim().email().min(5).max(255).required().label("Email"),
        password: Joi.string().trim().min(5).max(255).required().label("Password"),
        
    };

    const handleOnChange = ({ currentTarget: input }) => {
        const { data, errors } = handleOnChangeData( input, dataSchema, formData, formErrors);
        setFormData(data);
        setFormErrors(errors);
    };

    const doSubmit = async () => {
        // const {data:newItem} = await saveAttribute(formData);
        // const items = [...data, newItem];
        // setData(items);
        // setShow(!show);
        // toast.success('Attribute created successfully.');
    };

  return (
    <>
        <div className="login-section signup">
            <div className="d-flex flex-column flex-md-row login-section__content">
                <div className="login-section__left position-relative">
                    <Link to="/" className="icon-back">
                        <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="white" className="bi bi-arrow-left" viewBox="0 0 16 16">
                            <path fillRule="white" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
                        </svg>
                    </Link>
                    <img src="assets/img/login.png" alt="login image" className="img-fluid h-100" />
                </div>
                <div className="login-section__right">
                    <div className="blur-bg blur1" />
                    <div className="blur-bg blur2" />
                    <div className="login-content">
                        <div className="login-section__right__heading">
                            <h3 className="fs-40 fw-600 text-black text-center">Sign up to Analyxus</h3>
                            <p className="fs-18 fw-500 text-black text-center font-dm">Already have an account? <Link to={adminPanel} className="primary-text">Sign in</Link></p>
                        </div>
                        
                        <div className="google-login">
                            <button className="button w-100 google-btn">
                                <img src="assets/img/google.png" alt="google" />
                                <span className="ms-2">Sign in with Google</span>
                            </button>
                        </div>
                        
                        <div className="continue-line position-relative">
                            <p className="text-black font-dm fw-500 text-center mb-0">Or continue with</p>
                            <div className="line" />
                        </div>
                      
                        <form action="">
                            <Input type='text' value={formData["name"]} name='name' onChange={handleOnChange} label="Your Name" errors={formErrors["name"]}/>
                            <Input type='email' value={formData["email"]} name='email' onChange={handleOnChange} label="Your email" errors={formErrors["email"]}/>
                            <div className="form-group">
                                <label htmlFor="password" className="text-black fnt-dm fw-500 d-block form-label">Your password</label>
                                <div className="show-pass">
                                    <input type="password" className="form-input" id="Showpassword" />
                                    <button className=" toggle-btn" type="button" id="togglePassword">
                                        <svg className="eye" width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clipPath="url(#clip0_1_1371)">
                                            <path d="M15.5131 6.27886C14.4791 4.59486 12.1271 1.76953 7.99906 1.76953C3.87106 1.76953 1.51906 4.59486 0.485058 6.27886C0.16541 6.79587 -0.00390625 7.39169 -0.00390625 7.99953C-0.00390625 8.60737 0.16541 9.20319 0.485058 9.7202C1.51906 11.4042 3.87106 14.2295 7.99906 14.2295C12.1271 14.2295 14.4791 11.4042 15.5131 9.7202C15.8327 9.20319 16.002 8.60737 16.002 7.99953C16.002 7.39169 15.8327 6.79587 15.5131 6.27886ZM14.3764 9.0222C13.4884 10.4662 11.4784 12.8962 7.99906 12.8962C4.51972 12.8962 2.50972 10.4662 1.62172 9.0222C1.43181 8.7149 1.33122 8.36078 1.33122 7.99953C1.33122 7.63828 1.43181 7.28417 1.62172 6.97686C2.50972 5.53286 4.51972 3.10286 7.99906 3.10286C11.4784 3.10286 13.4884 5.5302 14.3764 6.97686C14.5663 7.28417 14.6669 7.63828 14.6669 7.99953C14.6669 8.36078 14.5663 8.7149 14.3764 9.0222Z" fill="#090202" />
                                            <path d="M7.99935 4.66797C7.34008 4.66797 6.69561 4.86347 6.14745 5.22974C5.59929 5.59601 5.17204 6.1166 4.91975 6.72569C4.66746 7.33478 4.60145 8.005 4.73007 8.6516C4.85868 9.29821 5.17615 9.89215 5.64233 10.3583C6.1085 10.8245 6.70245 11.142 7.34905 11.2706C7.99565 11.3992 8.66587 11.3332 9.27496 11.0809C9.88405 10.8286 10.4046 10.4014 10.7709 9.8532C11.1372 9.30504 11.3327 8.66057 11.3327 8.0013C11.3316 7.11757 10.9801 6.27034 10.3552 5.64545C9.73031 5.02056 8.88308 4.66903 7.99935 4.66797ZM7.99935 10.0013C7.60379 10.0013 7.21711 9.884 6.88821 9.66424C6.55931 9.44448 6.30297 9.13212 6.15159 8.76667C6.00022 8.40122 5.96061 7.99908 6.03778 7.61112C6.11495 7.22316 6.30543 6.86679 6.58514 6.58709C6.86484 6.30738 7.22121 6.1169 7.60917 6.03973C7.99713 5.96256 8.39927 6.00217 8.76472 6.15354C9.13017 6.30492 9.44253 6.56126 9.66229 6.89016C9.88205 7.21906 9.99935 7.60574 9.99935 8.0013C9.99935 8.53174 9.78864 9.04044 9.41356 9.41552C9.03849 9.79059 8.52978 10.0013 7.99935 10.0013Z" fill="#090202" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_1_1371">
                                                <rect width={16} height={16} fill="white" />
                                            </clipPath>
                                        </defs>
                                        </svg>
                                        <svg className="slash-eye" xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" viewBox="0 0 16 16">
                                            <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z" />
                                            <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z" />
                                            <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z" />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        
                            <div className="agree mt-3 mb-5">
                                <input type="checkbox" />
                                <span>
                                    <svg width={14} height={10} viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0.179962 6.71546L2.85105 9.38654C3.60217 10.1374 4.81973 10.1374 5.57085 9.38654L13.82 1.13736C14.066 0.882649 14.059 0.476765 13.8043 0.23077C13.5558 -0.00921485 13.1619 -0.00921485 12.9134 0.23077L4.66426 8.47995C4.41388 8.73024 4.00802 8.73024 3.75767 8.47995L1.08658 5.80887C0.831872 5.56287 0.425987 5.56993 0.179992 5.82465C-0.0599923 6.0731 -0.0599923 6.467 0.179962 6.71546Z" fill="white" />
                                    </svg>
                                </span>
                                <p>I accept the terms and conditions.</p>
                            </div>
                            <button className="button button__primary w-100" type="submit">Sign Up</button>
                            <p className="text-black fs-18 mb-0">Already have an account? <Link to={adminPanel} className="primary-text">Sign in</Link></p>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </>
  );
}
