import { Link } from 'react-router-dom';

export default function GenoMap() {
  return (
    <>
   
    <div className="inner-heading">
        <div className="container sml-container">
            <h2 className="medium-heading text-black">Genomap</h2>
        </div>
    </div>
   
    <div className="example-section py-96px">
        <div className="container sml-container">
            <div className="row align-items-md-center">
                <div className="col-md-6 example-left mb-3">
                    <div className="position-relative example-left__content">
                        <h3 className="text-black fw-600 fs-40 mb-24px">Genomap</h3>
                        <p className="mb-24px text-gray font-dm">Genomap is an entropy-based cartography strategy to contrive the high dimensional gene expression data into a configured image format, referred to as genomap, with explicit integration of the genomic interactions. This unique cartography casts the gene-gene interactions into the spatial configuration of genomaps and enables us to extract the deep genomic interaction features and discover underlying discriminative patterns of the data.Reference: Islam, M.T., and Xing, L., 2023. Cartography of Genomic Interactions Enables Deep Analysis of Single-Cell Expression Data, in press, Nature Communications.
                        </p>
                        <div className="mt-4">
                            <Link to="/" className="button button__primary">See Example</Link>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 example-right">
                    <img src="assets/img/tool1.png" alt="exaple image" className="img-fluid" />
                </div>
            </div>
        </div>
    </div>
    </>
  );
}
