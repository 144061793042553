import { Link } from 'react-router-dom';
import { youtubeLink1, youtubeLink2 } from '../config';

export default function Index() {
  return (
    <>
        <section className="top-section">
            <section className="banner-section secondery-bg banner-sapcing d-flex align-items-center justify-content-center position-relative overflow-hidden">
                <div className="blur-effect blur-left" />
                <div className="blur-effect blur-right" />
                <div className="banner-section__content text-center">
                    <h1 className="heading-big text-black">WELCOME  TO ANALYXUS</h1>
                    <p className="font-dm para mx-auto mb-0">Analyxus is a platform that provides a series of analysis methods for tabular data. In particular, the platform offers  state-of-art deep learning methods for analyzing a variety of types of tabular data, such as biomedical sensory data, clinical outcome data, single-cell data, and other omics data. Click <here> to learn more and see example use cases.</here></p>
                    <div className="banner-section__content__button">
                        {/* <Link to="/" className="button button__primary">Sign On</Link> */}
                    </div>
                </div>
            </section>

            <section className="top-video-section h-100 bg-primary-shade">
                <div className="container sml-container">
                    <h2 className="medium-heading text-black text-center">(Rotating DNA Helix,sensory,ECG,cardiac data,DNN etc.)</h2>
                    <iframe className="w-100 video" height={340} src={youtubeLink1} title="Oporadhi | Ankur Mahamud Feat Arman Alif | Bangla Song 2018 | Official Video" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen />
                </div>
            </section>
        </section>

        <section className="video-section video-sec-spacing min-h-100vh h-100 bg-white">
            <div className="container sml-container">
                <h2 className="medium-heading text-black text-center">A Centralized Platform for Processing Tabular Data</h2>
                <iframe className="w-100 video" height={650} src={youtubeLink2} title="Oporadhi | Ankur Mahamud Feat Arman Alif | Bangla Song 2018 | Official Video" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen />
            </div>
        </section>

        <section className="slider-section slider-spacing">
            <div className="container sml-container">
                <div className="slider">
                    <h2 className="medium-heading text-black text-center mb-24px">How it works</h2>
                    <p className="font-dm mb-5 text-gray text-center">We make it easy for you</p>
                    <div id="carouselExampleIndicators" className="carousel slide" data-bs-wrap="false">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={0} className="active" aria-current="true" aria-label="Slide 1" />
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={1} aria-label="Slide 2" />
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={2} aria-label="Slide 3" />
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={3} aria-label="Slide 4" />
                        </div>
                    
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <div className="item-body">
                                <div className="carousel-item__left bg-light-blue">
                                    <h3 className="fs-40 fw-500 text-black mb-24px">Upload File</h3>
                                    <ul className="list-item">
                                        <li>File must be in .csv format to start</li>
                                        <li>Input your previous session ID</li>
                                    </ul>
                                    <Link to="/geno-map" className="button button__primary">Show Details</Link>
                                </div>
                                <div className="carousel-item__right">
                                    <img src="assets/img/how-work1.png" alt="analyxus" className="img-fluid" />
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <div className="item-body">
                                <div className="carousel-item__left bg-pink">
                                    <h3 className="fs-40 fw-500 text-black mb-24px">Select Visualization Methods</h3>
                                    <ul className="list-item">
                                        <li>Deep learning embedding of Genomaps</li>
                                        <li>t-SNE of input data</li>
                                        <li>UMAP of input data</li>
                                        <li>PCA of input data</li>
                                    </ul>
                                    <Link to="/geno-map" className="button button__primary">Show Details</Link>
                                </div>
                                <div className="carousel-item__right">
                                    <img src="assets/img/how-work1.png" alt="analyxus" className="img-fluid" />
                                </div>
                            </div>
                        </div>

                        <div className="carousel-item">
                            <div className="item-body">
                                <div className="carousel-item__left bg-shade-blue">
                                    <h3 className="fs-40 fw-500 text-black mb-24px">Analysis</h3>
                                    <ul className="list-item">
                                        <li>Run analysis</li>
                                        <li>Create result downloaded data</li>
                                    </ul>
                                    <Link to="/geno-map" className="button button__primary">Show Details</Link>
                                </div>
                                <div className="carousel-item__right">
                                    <img src="assets/img/how-work2.png" alt="analyxus" className="img-fluid" />
                                </div>
                            </div>
                        </div>

                        <div className="carousel-item">
                            <div className="item-body">
                                <div className="carousel-item__left bg-white">
                                    <h3 className="fs-40 fw-500 text-black mb-24px">Display Your Results</h3>
                                    <ul className="list-item">
                                        <li>Show uploaded data</li>
                                        <li>Show Genomaps embeddings</li>
                                        <li>Show visualization results</li>
                                    </ul>
                                    <Link to="/geno-map" className="button button__primary">Show Details</Link>
                                </div>
                                <div className="carousel-item__right">
                                    <img src="assets/img/how-work2.png" alt="analyxus" className="img-fluid" />
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true">
                        <svg width={25} height={48} viewBox="0 0 25 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M23.6351 46.4043C23.9475 46.0944 24.1955 45.7258 24.3647 45.3196C24.534 44.9134 24.6211 44.4777 24.6211 44.0376C24.6211 43.5976 24.534 43.1619 24.3647 42.7557C24.1955 42.3495 23.9475 41.9808 23.6351 41.671L8.36843 26.4043C8.05601 26.0944 7.80803 25.7258 7.6388 25.3196C7.46957 24.9134 7.38244 24.4777 7.38244 24.0376C7.38244 23.5976 7.46957 23.1619 7.6388 22.7557C7.80803 22.3495 8.05601 21.9808 8.36843 21.671L23.6351 6.4043C23.9475 6.09443 24.1955 5.72576 24.3647 5.31956C24.534 4.91336 24.6211 4.47767 24.6211 4.03764C24.6211 3.5976 24.534 3.16191 24.3647 2.75571C24.1955 2.34951 23.9475 1.98084 23.6351 1.67097C23.0106 1.05013 22.1657 0.70166 21.2851 0.70166C20.4045 0.70166 19.5596 1.05013 18.9351 1.67097L3.6351 16.971C1.76243 18.846 0.710567 21.3876 0.710566 24.0376C0.710566 26.6876 1.76243 29.2293 3.6351 31.1043L18.9351 46.4043C19.5596 47.0251 20.4045 47.3736 21.2851 47.3736C22.1657 47.3736 23.0106 47.0251 23.6351 46.4043Z" fill="black" />
                        </svg>
                        </span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true">
                        <svg width={25} height={48} viewBox="0 0 25 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.3649 1.5957C1.05247 1.90558 0.804489 2.27425 0.63526 2.68044C0.466031 3.08664 0.378904 3.52233 0.378904 3.96236C0.378904 4.4024 0.466031 4.83809 0.63526 5.24429C0.804489 5.65049 1.05247 6.01915 1.3649 6.32903L16.6316 21.5957C16.944 21.9056 17.192 22.2742 17.3612 22.6804C17.5304 23.0866 17.6176 23.5223 17.6176 23.9624C17.6176 24.4024 17.5304 24.8381 17.3612 25.2443C17.192 25.6505 16.944 26.0192 16.6316 26.329L1.3649 41.5957C1.05247 41.9056 0.804491 42.2742 0.635262 42.6804C0.466033 43.0866 0.378906 43.5223 0.378906 43.9624C0.378906 44.4024 0.466033 44.8381 0.635262 45.2443C0.804491 45.6505 1.05247 46.0192 1.3649 46.329C1.98944 46.9499 2.83428 47.2983 3.7149 47.2983C4.59552 47.2983 5.44036 46.9499 6.0649 46.329L21.3649 31.029C23.2376 29.154 24.2894 26.6124 24.2894 23.9624C24.2894 21.3124 23.2376 18.7707 21.3649 16.8957L6.0649 1.5957C5.44036 0.974865 4.59552 0.626388 3.7149 0.626388C2.83428 0.626388 1.98944 0.974865 1.3649 1.5957Z" fill="black" />
                        </svg>
                        </span>
                        <span className="visually-hidden">Next</span>
                    </button>
                    </div>
                </div>
            </div>
        </section>
 
        <section className="tools-section tools-spacing">
            <div className="container sml-container">
                <h2 className="medium-heading text-black text-center mb-24px">Our tools</h2>
                <p className="font-dm mb-5 text-gray text-center">Derive useful insights with our cutting-edge techniques</p>
            </div>
        
            <div className="tools-tabs position-relative">
                <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button className="nav-link active" id="pills-genomaps-tab" data-bs-toggle="pill" data-bs-target="#pills-genomaps" type="button" role="tab" aria-controls="pills-genomaps" aria-selected="true"><span>Genomaps</span></button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false"><span>Deep Neural <br /> Network</span></button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false"><span>Tabular Data</span></button>
                    </li>
                </ul>
                <div className="container sml-container">
                    <div className="tab-content" id="pills-tabContent">
                        <div className="tab-pane fade show active " id="pills-genomaps" role="tabpanel" aria-labelledby="pills-genomaps-tab" tabIndex={0}>
                            <div className="d-flex flex-column flex-md-row align-items-center justify-content-between">
                                <div className="left">
                                    <h3 className="fs-32 text-black fw-500 mb-24px">Genomap</h3>
                                    <p className="text-gray">Genomap is an entropy-based cartography strategy to contrive the high dimensional gene expression data into a configured image format, referred to as genomap, with explicit integration of the genomic interactions. This unique cartography casts the gene-gene interactions into the spatial configuration of genomaps and enables us to extract the deep genomic interaction features and discover underlying discriminative patterns of the data.Reference: Islam, M.T., and Xing, L., 2023. Cartography of Genomic Interactions Enables Deep Analysis of Single-Cell Expression Data, in press, Nature Communications.</p>
                                    <Link to="/geno-map" className="button button__primary mt-4">See example</Link>
                                </div>
                            <div className="right">
                                <img src="assets/img/tool1.png" alt="toool image" className="img-fluid" />
                            </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab" tabIndex={0}>
                            <div className="d-flex flex-column flex-md-row align-items-center justify-content-between">
                            <div className="left">
                                <h3 className="fs-32 text-black fw-500 mb-24px">Deep Neural Network</h3>
                                <p className="text-gray">Genomap is an entropy-based cartography strategy to contrive the high dimensional gene expression data into a configured image format, referred to as genomap, with explicit integration of the genomic interactions. This unique cartography casts the gene-gene interactions into the spatial configuration of genomaps and enables us to extract the deep genomic interaction features and discover underlying discriminative patterns of the data.Reference: Islam, M.T., and Xing, L., 2023. Cartography of Genomic Interactions Enables Deep Analysis of Single-Cell Expression Data, in press, Nature Communications.</p>
                                <Link to="/deep-neural-network" className="button button__primary mt-4">See example</Link>
                            </div>
                            <div className="right">
                                <img src="assets/img/how-work1.png" alt="toool image" className="img-fluid" />
                            </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab" tabIndex={0}>
                            <div className="d-flex flex-column flex-md-row align-items-center justify-content-between">
                            <div className="left">
                                <h3 className="fs-32 text-black fw-500 mb-24px">Tebular Data</h3>
                                <p className="text-gray">Tebular Data is an entropy-based cartography strategy to contrive the high dimensional gene expression data into a configured image format, referred to as genomap, with explicit integration of the genomic interactions. This unique cartography casts the gene-gene interactions into the spatial configuration of genomaps and enables us to extract the deep genomic interaction features and discover underlying discriminative patterns of the data.Reference: Islam, M.T., and Xing, L., 2023. Cartography of Genomic Interactions Enables Deep Analysis of Single-Cell Expression Data, in press, Nature Communications.</p>
                                <Link to="/feature-embedding-machine" className="button button__primary mt-4">See example</Link>
                            </div>
                            <div className="right">
                                <img src="assets/img/how-work2.png" alt="toool image" className="img-fluid" />
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  );
}
