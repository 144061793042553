import React from "react";

const Input = ({ label, name, errors, ...rest }) => {
  return (
    <div className='form-group'>
      <label className="text-black fnt-dm fw-500 d-block form-label" htmlFor={name}>{label}</label>
      <input style={errors && {border: '1px solid red'}} className='form-input' id={name} name={name} {...rest} />
      {errors && <div className='my-2 text-danger'>{errors}</div>}
    </div>
  );
};

export default Input;