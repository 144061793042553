import { Route, Routes, useLocation } from "react-router-dom";
import Footer from "./layouts/Footer";
import Navbar from "./layouts/Navbar";
import Index from "./pages/Index";
import AboutUs from "./pages/about/AboutUs";
import ContactUs from "./pages/about/ContactUs";
import Publication from "./pages/news/Publication";
import RecentNews from "./pages/news/RecentNews";
import ContrastiveFeatureAnalysis from "./pages/product/ContrastiveFeatureAnalysis";
import FeatureEmbeddingMachine from "./pages/product/FeatureEmbeddingMachine";
import GenoMap from "./pages/product/GenoMap";
import Faq from "./pages/resource/Faq";
import Pricing from "./pages/resource/Pricing";
import PricingTwo from "./pages/resource/PricingTwo";
import Tutorial from "./pages/resource/Tutorial";
import SignIn from "./pages/user/SignIn";
import SignUp from "./pages/user/SignUp";

function App() {
  const { pathname } = useLocation();
  console.log(pathname);
  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/sign-in" element={<SignIn />} />
        <Route path="/sign-up" element={<SignUp />} />
        <Route path="/geno-map" element={<GenoMap />} />
        <Route path="/deep-neural-network" element={<ContrastiveFeatureAnalysis />} />
        <Route path="/feature-embedding-machine" element={<FeatureEmbeddingMachine />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/free-trial" element={<PricingTwo />} />
        <Route path="/tutorials" element={<Tutorial />} />
        <Route path="/recent-news" element={<RecentNews />} />
        <Route path="/publication" element={<Publication />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/faq" element={<Faq />} />
      </Routes>
      {!(pathname === "/sign-up" || pathname ==="/sign-in") && <Footer />}
    </>
  );
}

export default App;
